.accordion_section {
  background: url(/public/assets/images/main/black_bg_.png);
  background-size: cover;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 55vh;
  background-position: 34% 100%;
}


.accordion_section .top_accordion{
  position: relative;
  top: 10vh;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-top: none !important;
  border-radius: 9px !important;
  margin-bottom: 3vh;
}

.accordion_stroke_title{
  color: black;
  font-size: 6.5em;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: white;
  font-size: 5em;
}



.css-67l5gl{
    border-top: none !important;
  border-radius: 9px !important;
  margin-bottom: 3vh;
  box-shadow: 5px 5px 5px 3px #adb5bd !important
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  position: absolute;
  left: 0;
  top: 0px !important;
  right: 0;
  height: 0 !important ;
  content: "";
  opacity: 1;
  background-color: none !important;
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.css-i4bv87-MuiSvgIcon-root {
  fill: var(--red) !important;
  display: flex !important;
  justify-content: start !important;
}

.square_icon_accordion {
  font-size: 3em;
  color: var(--red);
  padding-bottom: 3.3vh;
}

.accordion_down_icon {
  color: var(--red);
  width: 0.8em;
  margin-right: 1vh;
}

.accordion_down_icon_top {
  color: var(--red);
  width: 0.8em;
  margin-right: 1vh;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  display: none !important;
}

.server_img {
  max-width: 100%;
  padding-left: 8vh;
}

.home_accordion_title {
  font-size: 17px !important;
}

/* .res_cloud_img {
  display: flex;
  justify-content: center;
  align-items: center;
} */

@media screen and (max-width: 992px) {
  .accordion_section{
    padding-bottom: 30vh;
  
  }

  .server_img {
    padding-top: 10vh;
    max-width: 100%;
  }

  .res_cloud_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 568px) {
  .accordion_section {
    padding-top: 0vh;
  }
  .res_cloud_img {
    display: flex;
    justify-content: center;
  }

 

  .server_img {
    width: 50vh;
    padding-left: 0vh;
  }
  .home_accordion_title {
    font-size: 16px !important;
    font-weight: bold;
  }

  .accordion_margin {
    margin-top: 10vh;
  }

  .accordion_stroke_title {
    font-size: 3em;
}
}
