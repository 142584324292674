.need_img {
  width: 150%;
}

.need_page {
  padding-top: 20vh;
  padding-bottom: 20vh;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.need_gif {
  width: 50vh;
}

.stroke_title_need {
  line-height: 0.9em;
  color: black;
  font-size: 4.5em;
  -webkit-text-stroke: 2px var(--black);
  -webkit-text-fill-color: white;
}

.need_title {
  font-size: 4.5em;
}

.need_res {
  display: none;
}

.rotating-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  transition: transform 1s ease-in-out;
}

.rotating-image.show {
  transform: translateX(-50%) rotate(360deg);
}

.need_contact_button {
  color: var(--black) !important;
  border-radius: 9px;
  border: 1px solid var(--black);
  background-color: var(--white);
  -webkit-transition: ease-out 0.6s;
  -moz-transition: ease-out 0.6s;
  transition: ease-out 0.6s;
}

.form-select:focus {
    border-color: black;
    outline: 0;
    box-shadow: 0 0 0 0.25rem transparent;
}

.form-select{
  border-bottom: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-top: transparent !important;
  border-right: transparent !important;
border-radius: 0px !important;
}
  

.need_contact_button:hover {
  color: white !important;
  background-color: var(--black);
  box-shadow: inset 600px 0 0 0 var(--black);
}

.padding_button_need {
  width: 100%;
  padding: 0.5vh 16vh 0.5vh 16vh;
}


.need_page_text{
  display: flex;
  justify-content: center;
  align-items: start;
}

@media screen and (max-width: 1500px) {
  .need_gif {
    width: 36vh;
  }
}

@media screen and (max-width: 992px) {
  .need_res {
    display: block;
    width: 50%;
    position: relative;
    left: 12vh;
  }
  .need_gif {
    display: none;
  }


.center_row_need{
 display: flex;
 justify-content: center;
  }


  .need_page {
    padding-top: 10vh;
    padding-bottom: 10vh;

}


.need_page_text {
  display: flex;
  justify-content: center;
  align-items: center;
}
}

@media screen and (max-width: 568px) {

  .need_res {
    display: block;
    width: 50%;
    position: relative;
    left: 8vh;
  }
  .need_page {
    padding-top: 5vh;
    padding-bottom: 10vh;
  }
  .stroke_title_need {
    font-size: 2.4em;
    -webkit-text-stroke: 1px var(--black);
    -webkit-text-fill-color: white;
  }

  .need_title {
    font-size: 2.4em;
  }

  .padding_button_need {
    padding: 0.5vh 16vh 0.5vh 16vh;
  }

  .res_form_padding {
    padding-top: 3vh;
  }
}
