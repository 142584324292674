.black_bg_section {
 padding-top: 15vh;
  padding-bottom: 15vh;
  background-position: revert;
}

.product_dir_btn{
  border: 1px solid var(--black) !important;
  border-radius: 9px !important;
  width: 100%;
}

.stroke_title {
  line-height: 0.9em;
  color: black;
  font-size: 4em;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
}

.bg_section_visox_text_img {
  width: 70%;
  padding-top: 19vh;
}

.stroke_title_small {
  line-height: 0.9em;
  color: black;
  font-size: 1.5em;
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: black;
}

.center_visox_bg {
  margin-top: -20vh;
  margin-bottom: 15vh;
}

.visox_product {
  width: 80%;
}

.black_bg_section .swiper-slide{
  display: flex;
  justify-content: center;
}

.accordion_title_stroke{
  color: black;
  font-size: 4.5em;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: white;
}


/* .accordion_stroke_inner{
  max-width: 20%;

} */


.black_bg_section .swiper-button-next{
top: 50% !important;
color: black;
font-size: 6.5em;
-webkit-text-stroke: 1px var(--red);
-webkit-text-fill-color: white;
}
.black_bg_section  .swiper-button-prev{
top: 50% !important;
color: black;
font-size: 6.5em;
-webkit-text-stroke: 1px var(--red);
-webkit-text-fill-color: white;
}


.black_bg_section .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  font-size: 0.8em;
}

.black_bg_section .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
  font-size: 0.8em;

}


.swiper_product_one{
  width: 60%;
}
.swiper_product_two{
  width: 40%;
}
.swiper_product_three{
  width: 90%;
}

.black_bg_accordion {
  background-color: white !important;
  border-radius: 9px;
  border: 1px solid black;
  border-top: 1px solid black !important;
  border-right: 1px solid black;
  box-shadow: 5px 5px 5px 3px transparent !important;
}

.black_bg_text_white {
  color: var(--black);
}

.visox_product_home_res {
  display: flex;
  justify-content: center;
}

.black_bg_render_img {
  width: 70%;
}

.res_bg_section {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .visox_product_home_res {
    text-align: center;
  }

  .visox_product {
    width: 60%;
  }

  .stroke_title {
    font-size: 3.5em;
  }

  .black_bg_section {
    padding-bottom: 10vh;
  }
}

@media screen and (max-width: 568px) {
  .black_bg_section .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    font-size: 0.4em;
}

.black_bg_section .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  font-size: 0.4em;
}

  .black_bg_section {
    background-size: 100vh;
    padding-bottom: 0;
    background-repeat: no-repeat;
  }

  .stroke_title {
    line-height: 0.9em;
    color: black;
    font-size: 1.6em;
  }

  .res_bg_section {
    margin-top: 5vh;
  }

  .res_accordion_black {
    padding-top: 12vh !important;
    margin-bottom: 5vh;
  }

  .visox_product_home_res {
    padding-top: 5vh;
    text-align: center;
  }

  .center_visox_bg {
    margin-top: 0;
    margin-bottom: 0;
  }


}
