.xs {
  pointer-events: none;
}
.product {
  overflow-x: hidden !important;
}

.product_tab {
  overflow-x: hidden !important;
}

.product_accordion_bottom {
  overflow-x: hidden !important;
}

.accordion_input {
  display: none;
}

.visox_product_video {
  width: 100%;
  border-radius: 20px;
}

.model-viewer {
  width: 800px;
  height: 600px;
}

.product_nav {
  background-color: var(--white);
  position: fixed;
  border-bottom: 1px solid var(--black);
  width: 100%;
}

.product_accordion {
  background-color: var(--white) !important;
  border-radius: 0px !important;
  box-shadow: 5px 5px 5px 3px transparent !important;
  /*   border-bottom: 1px solid black; */
}

.line_product {
  border-bottom: 1px solid var(--black);
  margin-bottom: 3vh;
}

.product_desc {
  font-size: 11px !important;
  padding-left: 1.5vh;
  opacity:1;
}

.product_plus_img {
  padding: 8vh 0vh 8vh 0vh;
  width: 15%;
}

/* .border-right {
  padding: 8px 16px 16px !important;
  border-right: 1px solid black !important;
  border-top: 1px solid black;
} */

/* .feature_1 {
  width: 80%;
  padding: 5vh;
}

.feature_2 {
  width: 100%;
  padding: 5vh;
}
.feature_3 {
  width: 80%;
  padding-top: 5vh;
}
.feature_4 {
  width: 100%;
  padding: 5vh;
} */

/* .owl-carousel.owl-drag .owl-item {
  width: auto !important;
} */

.owl-stage {
  display: flex;
}

.owl-carousel {
  width: 350vh !important;
}

.feature_5 {
  height: 112.5%;
}
.feature_6 {
  height: 112.5%;
}
.feature_7 {
  height: 100%;
}

.math_section {
  padding: 2vh;
}

.hidden_img {
  visibility: hidden;
}

.border {
  border: 8px solid black;
}

.button_slide {
  color: #fff;
  background-color: var(--black);
  border: 1px solid var(--white);
  border-radius: 9px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 var(--white);
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.slide_right:hover {
  box-shadow: inset 400px 0 0 0 var(--white);
}

.border_bottom_product {
  border-bottom: 1px solid var(--black);
}

.plus_product_img {
  width: 15%;
}

.visox_product_img {
  width: 35%;
}

.feature_2 {
  width: 43%;
}

.res_button_one {
  display: flex;
  justify-content: center;
}

.res_button_two {
  display: flex;
  justify-content: center;
}

.res_button_three {
  display: flex;
  justify-content: center;
}

.res_button_four {
  display: flex;
  justify-content: center;
}

.btn_one {
  padding: 3.2vh;
}

.res_button_one {
  padding-bottom: 2vh;
}
.res_button_two {
  padding-bottom: 2vh;
}
.res_button_three {
  padding-bottom: 2vh;
}
.res_button_four {
  padding-bottom: 2vh;
}

.padding_3 {
  padding-top: 3vh;
}

.react-horizontal-scrolling-menu--wrapper {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.carousel .carousel-status {
  display: none;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}

.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  background: #fff;
  border-radius: 50%;
  width: 13px !important;
  height: 13px !important;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
  box-shadow: 1px 1px 2px transparent !important;
}

@media screen and (min-width: 2560px) {
  .product_form_left {
    padding-left: 1.5vh;
  }
}

@media screen and (max-width: 568px) {
  .product_plus_img {
    width: 50%;
  }

  .btn_one {
    padding: 3vh !important;
  }

  /*   .res_button_one {
    display: flex;
    justify-content: start;
    padding-bottom: 2vh;
  }

  .res_button_two {
    display: flex;
    justify-content: end;
    padding-bottom: 2vh;
    margin-top: -10vh;
  }

  .res_button_three {
    display: flex;
    justify-content: start;
    padding-bottom: 2vh;
  }

  .res_button_four {
    display: flex;
    justify-content: end;
    margin-top: -9vh;
  }

  .btn_one {
    padding: 2.1vh;
  }
  .btn_two {
    padding: 0.9vh;
  }
  .btn_three {
    padding: 0.7vh;
  }
  .btn_four {
    padding: 0.2vh;
  } */

  .product_form_left {
    padding-left: 0vh;
  }
}

.mobile-block {
  display: none;
}

@media screen and (max-width: 992px) {
  .btn_one {
    padding: 1.2vh;
  }
  .d-lg-block {
    display: none;
  }

  .mobile-block {
    display: block;
  }
}

@media screen and (max-width: 568px) {
  .css-15v22id-MuiAccordionDetails-root {
    padding: 8px -4px 15px !important;
  }

  .product_desc {
    font-size: 11px !important;
    padding-left: 1.5vh;
  }

  .visox_product_img {
    width: 85%;
  }
  .plus_product_img {
    width: 40%;
  }
}
