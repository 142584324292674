.nav {
  width: 100%;
  background: var(--black);
  position: relative;
  top: 0;
  height: 10vh;
  display: flex;
  justify-content: space-around;
}

.header_res {
  display: none;
}

nav ul li a.active {
  opacity: 1;
}
nav ul li a {
  opacity: 0.5;
  font-family: "Eurostile Extended", sans-serif;
  font-size: 17px;
}

.header_Navlink_language {
  opacity: 0.5 !important;
  padding: 0 10px;
  font-size: 14px !important;
}

.header_Navlink_language.active {
  opacity: 1;
}

.header_link {
  transition: 0.5s all ease;
}

nav ul li a:hover {
  opacity: 1 !important;
  text-decoration: none !important;
}

label {
  display: inline-block;
}

label.language__label {
  display: inline-block;
}

nav li {
  display: inline;
}

#nav-button {
  display: none;
}

nav a {
  text-decoration: none;
  color: var(--gray);
  padding: 0px 40px;
}

.language_text {
  padding: 0px 120px;
}

@media screen and (max-width: 1383px) {
  .nav {
    display: none !important;
  }

  .header_res {
    display: block !important;
  }
}

:root {
  --home-bg-color: #f2f3ee;
  --menu-bg-color: #cbcbc2;
  --silde-btn-border: #808080;
  --slide-btn-bg: #ddf2db;
  --slide-btn-hoverbg: #f1fff1;
  --alpha-green: rgba(33, 96, 47, 0.51);
  --icon-hover-color: #344a39;
  --icon-hover-bg: #709680;
  --text-color: #616161;
  --border-color: #709680;
  --heading-color: #344a39;
  --box-shadow-color: #b5b5ac;
  --lightest-green: #86a58d;
  --light-green: #9ab09a;
  --dark-green: rgba(52, 74, 57, 0.86);
  --box-shadow: 0px 0px 3px 5px var(--box-shadow-color);
  --border-radius: 60px 5px;
  --fade-green: rgba(57, 87, 64, 0.55);
}

#ham-menu {
  display: none;
}
label[for="ham-menu"] {
  cursor: pointer;
  display: block;
  position: fixed;
  top: 2vh;
  left: 0;
  z-index: 999;
  width: 60px;
  height: 60px;
  border-radius: 15px;
}
.ham-menu {
  z-index: 99999999999999999999999;
  width: 50vw;
  height: 100%;
  position: fixed;
  top: 0;
  visibility: hidden;
  transform: translate(-110%);
  z-index: 998;
  background-color: var(--black);
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ham-menu > ul {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  padding: 130px;
  height: 50%;
  line-height: 2.5em;
}
.ham-menu > ul > li {
  text-align: start;
  font-size: 1rem;
  white-space: nowrap;
  letter-spacing: 0.15em;
  cursor: pointer;
  color: rgb(97, 97, 97);
}
#ham-menu:checked + label {
  background-color: transparent;
  border-color: var(--dark-green);
}
#ham-menu:checked ~ div.ham-menu {
  transform: translate(0px);
  visibility: visible;
}
.full-page-green {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1818a1;
  z-index: 997;
  opacity: 0;
  visibility: hidden;
  display: none;
  transition: 500ms;
  position: fixed;
  top: 0;
  left: 0;
}
#ham-menu:checked ~ div.full-page-green {
  display: block;
  opacity: 1;
  visibility: visible;
}
[for="ham-menu"] > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  align-items: center;
}
.menu-line {
  display: block;
  width: 34px;
  height: 2px;
  margin: 8px 0 -1px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: var(--white);
  transition: 500ms;
  transform-origin: right center;
}
[for="ham-menu"] > div > span:nth-child(4),
[for="ham-menu"] > div > span:nth-child(5),
[for="ham-menu"] > div > span:nth-child(6) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  transform-origin: left center;
}
#ham-menu:checked + label span {
  background-color: var(--dark-green);
}
#ham-menu:checked + label span:nth-child(2),
#ham-menu:checked + label span:nth-child(5) {
  transform: scale(0);
}
#ham-menu:checked + label span:nth-child(1) {
  transform: translateY(17px) rotate(45deg);
}
#ham-menu:checked + label span:nth-child(4) {
  transform: translateY(17px) rotate(-45deg);
}
#ham-menu:checked + label span:nth-child(3) {
  transform: translateY(-17px) rotate(-45deg);
}
#ham-menu:checked + label span:nth-child(6) {
  transform: translateY(-17px) rotate(45deg);
}

.text {
  font-size: 2.5rem;
  line-height: 1.5em;
  word-spacing: 0.5em;
  letter-spacing: 0.1em;
}
.title {
  font-size: 3rem;
  line-height: 1.5em;
  word-spacing: 0.5em;
  letter-spacing: 0.1em;
  color: var(--icon-hover-bg);
  padding: 10px;
}
.heading {
  font-size: 6rem;
  line-height: 1.5em;
  word-spacing: 0.5em;
  letter-spacing: 0.1em;
}
.centre-text {
  text-align: center;
}
.bold-text {
  font-weight: bold;
}

.header_res {
  height: 7vh;
  background-color: var(--black);
  position: sticky;
  z-index: 9999999;
  top: 0;
}

.res_logo_white {
  padding-top: 1.8vh;
}

.res_menu_link {
  color: rgb(97, 97, 97) !important;
}

.bottom_icon {
  position: relative;
  top: 30vh;
}

.social_media_icon_ham {
  color: rgb(97, 97, 97) !important;
  font-size: 1.6em;
}


.language__label__black{
  background-color: var(--black);
  color: var(--white);

      position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 30px;
    padding: 7px 18px 5px 0;
    background-repeat: no-repeat;
    background-position: 7px 7px;
    z-index: 9999999;
    background-size: 14px;
    text-align: right;
}


.language__container--left .language__label__black {
  border-radius: 4px 0 0 4px;
}
.language__container--right .language__label__black {
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.language__control:hover + .language__label__black,
.language__control:focus + .language__label__black {
  background-color: rgb(65, 65, 65);
}
.language__control:checked + .language__label__black {
  background-color: rgb(65, 65, 65);
}

@media screen and (max-width: 992px) {
  .bottom_icon {
    position: relative;
    top: 30vh;
  }

  label[for="ham-menu"] {
    left: 1vh;
  }
}

@media screen and (max-width: 568px) {
  .header_res {
    height: 9vh;
    background-color: var(--black);
    position: sticky;
    z-index: 999999999;
    top: 0;
  }
}
