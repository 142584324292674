.sss {
  min-height: 100vh;

  max-height: 300vh;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background-repeat: no-repeat;
  background-size: center;
  background-size: 30%;
  background-position: right bottom;
}

.sss_border_bottom {
  border-bottom: 1px solid var(--black);
}

.center_accordion {
  display: flex;
  justify-content: center;
  position: relative;
  top: 5vh;
  padding-bottom: 15vh;
}

.sss_visox_text {
  position: inherit;
}

.border_none {
  border-right: 1px solid transparent !important;
  border-top: 1px solid transparent !important;
}

.bottom-right-item {
  position: fixed;
  bottom: 3vh;
  right: 0;
}

.stroke_title_sss {
  line-height: 1em;
  letter-spacing: 1.5px;
  color: white;
  font-size: 4em;
  -webkit-text-stroke: 2px var(--black);
  -webkit-text-fill-color: white;
}


.sss_mb{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 1400px) {
  .sss {
    min-height: 100vh;

    max-height: 300vh;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    background-repeat: no-repeat;
    background-size: center;
    background-size: 60%;
    background-position: right bottom;
  }
}

@media screen and (max-width: 992px) {

  .res_sss_gif {
    width: 100%;
    padding: 2vh;
  }

  .sss {
    min-height: 100vh;
    max-height: 300vh;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    background-repeat: no-repeat;
    background-size: center;
    background-size: 70%;
    background-position: right bottom;
  }
}

@media screen and (max-width: 768px) {
  .sss {
    min-height: 100vh;

    max-height: 300vh;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    background-repeat: no-repeat;
    background-size: center;
    background-size: 80%;
    background-position: right bottom;
  }
}
@media screen and (max-width: 568px) {
  .stroke_title_sss {
    font-size: 1.5em;
    -webkit-text-stroke: 1px var(--black);
    -webkit-text-fill-color: white;
  }

  .res_sss_gif {
    width: 100%;
    padding: 2vh;
  }

  .sss {
    min-height: 100vh;
    max-height: 300vh;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    background-repeat: no-repeat;
    background-size: center;
    background-size: 100%;
    background-position: right bottom;
  }
}
