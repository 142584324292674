/* input {
  position: absolute;
  opacity: 0;
  z-index: -1;
} */

.tabs {
  z-index: 9999999999999999;
  border-radius: 8px;
  overflow: hidden;
}
.tab {
  width: 100%;
  color: var(--black);
  overflow: hidden;
}

.carousel .thumb{
  display: none !important;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 18px;
  background: var(--white);
  border: 1px solid var(--black);
  cursor: pointer;
  border-radius: 9px;
  -webkit-transition: ease-out 0.6s;
  -moz-transition: ease-out 0.6s;
  transition: ease-out 0.6s;
  /* Icon */
}
.tab-label:hover {
  background: var(--black);
  color: var(--white);
  box-shadow: inset 1200px 0 0 0 var(--black);
}
.tab-label::after {
  color: var(--red);
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: var(--white);
  background: var(--black);
  transition: all 0.35s;
}

label {
  margin-bottom: 0 !important;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #1a252f;
}
input:checked + .tab-label {
  color: var(--white);
  background: var(--black);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
input:checked + .tab-label::after {
  transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 141vh;
  padding: 1em;
}

.tab_list {
  display: flex;
  justify-content: center;
  padding: 2vh;
  border-bottom: 1px solid var(--black);
}

.tab_one {
  font-size: 1.5em;
  padding: 0 3vh;

  cursor: pointer;
  -webkit-text-stroke: 1px black !important;
  -webkit-text-fill-color: white !important;
}

.tab_two {
  font-size: 1.5em;
  cursor: pointer;
  padding: 0 2vh;
  -webkit-text-stroke: 1px black !important;
  -webkit-text-fill-color: white !important;
}

.react-tabs__tab {
  -webkit-text-stroke: 1px white !important;
  -webkit-text-fill-color: black !important;
}

.react-tabs__tab--selected {
  cursor: pointer;
  background: transparent !important;
  border: none !important;
  border-color: transparent !important;
  color: black;
  border-radius: 5px 5px 0 0;
  -webkit-text-stroke: 0.1px black !important;
  -webkit-text-fill-color: black !important;
}

.btn-outline-dark-contact {
  color: var(--white) !important;
  border-radius: 9px;
  border: 1px solid var(--white);
  background-color: var(--black);
  -webkit-transition: ease-out 0.9s;
  -moz-transition: ease-out 0.9s;
  transition: ease-out 0.9s;
}

.btn-outline-dark-contact:focus {
  background-color: white;
  color: #000 !important;
}

.btn-outline-dark-contact:hover {
  color: black !important;
  background-color: var(--white);
  box-shadow: inset 600px 0 0 0 var(--white);
}

.btn-outline-dark-math- {
  color: var(--white) !important;
  border-radius: 9px;
  background-color: var(--black);
  padding-top: 2vh;
  padding-right: 3vh;
  font-size: 28px;
}
.btn-outline-dark-math-- {
  color: var(--white) !important;
  border-radius: 9px;
  background-color: var(--black);
  padding-top: 2vh;
  padding-left: 2vh;

  font-size: 28px;
}

.high_opacity_contact {
  margin-bottom: 0vh;
}

.low_opacity_product {
  opacity: 0.5;
  font-size: 12px !important;
  margin-bottom: 0vh;
}

.form-control-product {
  background-color: var(--black);
  border-bottom: 2px solid var(--white);
  border-left: 2px solid var(--white);
  border-top: transparent !important;
  border-right: transparent !important;
  border-radius: 0 !important;
  width: 100%;
  padding-left: 1vh;
  padding-bottom: 1vh;
  font-size: 15px !important;
  color: var(--white);
}

.form-control-product:focus-visible{
  outline: none;
}

.product_form_left {
  padding-left: 6.5vh;
}

.product_visox {
  width: 50%;
}

.padding_button_product {
  padding: 0vh 8vh 0vh 8vh;
}

.button {
  background-color: #f0f0f0;
  border: none;
  color: #000;
  padding: 10px 20px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.button.active {
  background-color: #000;
  color: #fff;
}

li:focus-visible {
  outline: none !important;
}

@media screen and (max-width: 568px) {
  .tab_two {
    font-size: 1.3em;
  }
  .tab_one {
    font-size: 1.3em;
  }
  /*   .tab_two {
    font-size: 1.3em;
    cursor: pointer;
    padding: 0 1vh;
    -webkit-text-stroke: 0.8px black !important;
    -webkit-text-fill-color: white !important;
  } */

  /*   .tab_one {
    font-size: 1.3em;
    padding: 0 2vh;
  }

  .tab-label {
    font-size: 23px;
  } */
}
