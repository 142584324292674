.footer {
  background-color: var(--black) !important;
  background: url(/public/assets/images/logo/visox_icon_outline.svg);
  background-repeat: no-repeat;
  background-position: right;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99 !important;
  position: relative;
}

.footer ul {
  padding-left: 8vh;
}

.footer ul li {
  color: var(--white);
}

.line {
  border-bottom: 1px solid var(--white);
  margin-bottom: 4.8vh;
}

.social_media_icon {
  color: var(--white);
  font-size: 1.6em;
}

.footer-logo {
  padding-bottom: 3vh;
  padding-top: 3vh;
  display: flex;
  justify-content: center;
}

.footer_link:hover {
  opacity: 0.7;
  text-decoration: none !important;
}

.res_footer_icons {
  padding-bottom: 4vh;
}

.footer_res_texts {
  padding-bottom: 2.5vh;
}

.footer_logo_res_padding {
  padding-left: 5vh;
}

.osicrew_logo{
  margin-top: -1vh;
  padding-bottom: 3vh;
}

.madeby{
  padding-left: 4.5%;
}

.madeby p{
  font-size: 13px;
}

@media screen and (max-width: 992px) {
  .res_footer_icons {
    padding-left: 8vh;
  }

  .res_footer_icons {
    padding-bottom: 2vh;
  }

  .footer_res_texts {
    padding-bottom: 0vh;
  }
  .res_footer_icons {
    padding-left: 8vh;
  }
  .footer_logo_res_padding {
    padding-left: 0vh;
  }

  .footer ul {
    padding-left: 1vh !important;
  }

  .line {
    margin-bottom: 1vh;
  }

  .res_footer_icons {
    padding-left: 1vh !important;
  }

 /*  .footer {
    background-size: 38vh;
    height: 300px;
  } */
}

@media screen and (max-width: 568px) {
  .res_footer_icons {
    padding-left: 8vh;
  }
  .footer_logo_res_padding {
    padding-left: 0vh;
  }

  .footer ul {
    padding-left: 1vh !important;
  }

  .line {
    margin-bottom: 1vh;
  }

  .res_footer_icons {
    padding-left: 1vh !important;
  }

/*   .footer {
    background-size: 19vh;
    height: 300px;
  } */

  .footer_link {
    font-size: 14px;
  }

  .social_media_icon {
    font-size: 1.2em;
  }

  .social_media_icon_ham {
    font-size: 1.2em !important;
  }
}
