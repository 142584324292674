section {
  padding-top: 20vh;
  padding-bottom: 20vh;
}

a {
  color: #0d6efd;
  text-decoration: none !important;
}

/* p {
  font-size: 18px !important;
} */

.text-bold {
  font-weight: bold;
}

.text-900 {
  font-weight: 900;
}
.text_bold{
  font-weight: 900;
  opacity: 1 !important;
}

.pt-1 {
  padding-top: 1em !important;
}

.pt-02 {
  padding-top: 0.2em !important;
}

.pl-1 {
  padding-left: 1.2em !important;
}

.mt--1 {
  margin-top: -1vh;
}

.pl-05 {
  padding-left: 0.5em;
}

.pl-3 {
  padding-left: 2.5em !important;
}

.pt-3 {
  padding-top: 3vh !important;
}
.pl-4 {
  padding-left: 4vh !important;
}

.pb-2 {
  padding-bottom: 1.8vh !important;
}

.pb-3 {
  padding-bottom: 3.5vh !important;
}

.pb-25 {
  padding-bottom: 25vh;
}

.mr-3{
  margin-right: 3vh;
}

.mt-10vh {
  margin-top: 10vh;
}

.mt-20vh {
  margin-top: 30vh;
}

.slider_number {
  font-size: 4em;
  opacity: 0.2;
}

.font-48 {
  font-size: 34px !important;
}

.center_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

ul {
  list-style: none;
}

a {
  color: var(--white) !important;
}

.dark-bg {
  background-color: var(--black);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem transparent !important;
}

.pl-5 {
  padding-left: 5vh !important;
}

.text-end {
  text-align: end;
}

.mb-4 {
  margin-bottom: 4vh !important;
}

.border_bottom_res {
  border-bottom: 1px solid var(--black);
  padding-top: 2vh;
}

.form-group {
  padding-bottom: 2vh;
}

.form-group:focus-visible{
  outline: none;
}

.margin_0 {
  margin-bottom: 0rem;
}

.low_opacity {
  opacity: 0.7;
  font-size: 14px !important;
}

.form-floating>label{
  font-size: 13px;
}

@media screen and (max-width: 992px) {
  .font-48 {
    font-size: 38px !important;
    /*   padding-left: 4vh; */
  }
}

@media screen and (max-width: 568px) {
  .font-48 {
    font-size: 38px !important;
  }

  .slider_number {
    font-size: 2.5em;
    opacity: 0.2;
  }

  .form-control {
    font-size: 0.7rem;
  }

  .low_opacity {
    font-size: 12px !important;
  }
}
