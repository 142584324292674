.home_video {
  position: absolute;
  top: 5vh;
  left: 0;
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.mobil_home_video {
  display: none;
}

.overlay {
  padding-right: 10vh;
  min-height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: end;
}
 
.swiper_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6vh;
}

.arrow_icon {
  width: 0.5em;
  font-size: 27px;
  color: var(--red);
  margin-left: 11px;
}

.square_icon {
  font-size: 35px;
  color: var(--red);
  padding-left: 11px;
}

.height_number {
  height: 10vh;
}

.slider_number_one {
  font-size: 4em;
  opacity: 0.2;
}

.slider_number_two {
  font-size: 4em;
  opacity: 0.2;
}

.white_home_text {
  color: var(--white);
}

.white_home_text_p {
  color: var(--white);
  opacity: 0.8;
}

.swiper_height_class {
  flex-direction: column;
  display: flex;
  justify-content: end;
}

.home_swiper  .swiper-button-next{
  color: var(--red) !important;
  font-weight: 900 !important;
  top: var(--swiper-navigation-top-offset, 90%) !important;
}
.home_swiper 
.swiper-button-prev {
  color: var(--red) !important;
  font-weight: 900 !important;
  top: var(--swiper-navigation-top-offset, 90%) !important;
}
.mySwiper_res .swiper-button-next{
  color: var(--red) !important;
  font-weight: 900 !important;
  top: var(--swiper-navigation-top-offset, 90%) !important;
}
.mySwiper_res
.swiper-button-prev {
  color: var(--red) !important;
  font-weight: 900 !important;
  top: var(--swiper-navigation-top-offset, 90%) !important;
}

/* @media screen and (max-width: 376px), screen and (max-height: 668px) {
    .swiper-button-next,
    .swiper-button-prev {
      color: var(--red) !important;
      font-weight: 900 !important;
      top: var(--swiper-navigation-top-offset, 91%) !important;
    }
  } */

.mySwiper_res {
  display: none;
}

@media screen and (min-width: 1423px), screen and (min-height: 653px) {
  .slider_number_one {
    font-size: 3em;
    opacity: 0.2;
  }

  .slider_number_two {
    font-size: 3em;
    opacity: 0.2;
  }

  .home_swiper .swiper-button-next {
    color: var(--red) !important;
    font-weight: 900 !important;
    top: var(--swiper-navigation-top-offset, 93%) !important;
  }
  .home_swiper 
  .swiper-button-prev {
    color: var(--red) !important;
    font-weight: 900 !important;
    top: var(--swiper-navigation-top-offset, 93%) !important;
  }
}


@media screen and (max-width: 1439px) {
.res_font {
  font-size: 14px !important;
}
}




@media screen and (max-width: 992px) {
  .mobil_home_video {
    
    display: block;
  }

  .web_home_video {
    display: none;
  }

  .web_swiper {
    display: none;
  }

  .mySwiper_res {
    padding-top: 5vh;
    display: block;
  }
  .height_number {
    height: 5vh;
  }
}

@media screen and (max-width: 768px) {
  .swiper_height_class{
    padding: 25px;
  }

  .slider_number_one {
    font-size: 2.5em;
  }

  .slider_number_two {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 568px) {
 .overlay {
    padding-top: 10vh;
    padding-right: 0vh;
    min-height: 90vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  } 

  .slider_number_one {
    font-size: 2em;
    opacity: 0.2;
    padding-top: 1vh;
  }

  .slider_number_two {
    font-size: 2em;
    opacity: 0.2;
    padding-bottom: 1vh;
  }

  .res_font {
    font-size: 13px !important;
  }

  .res_title {
    padding-left: 2vh;
  }

  .height_number {
    height: 8vh;
  }
}
