/* App.css */

.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #555;
    color: white;
    border: none;
    padding: 0px 15px;
    font-size: 35px;
    border-radius: 4px;
    cursor: pointer;
    z-index: 999;
}

.scroll-to-top-button:hover {
    background-color: #333;
}