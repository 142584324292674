.nav_white {
  background-color: var(--white);
  color: var(--black);
  height: 10vh;
  z-index: 9;
}

.nav_white a {
  text-decoration: none;
  color: var(--gray) !important;
  padding: 0px 40px;
}

.header_Navlink_language {
  padding: 0 10px !important;
  font-size: 16px;
}

.language {
  font-size: 14px;
  line-height: 1;
  color: black;
  display: flex;
  align-items: center;
}
.language__container--left,
.language__container--right {
  position: relative;
  float: left;
  width: 45px;
  height: 30px;
  padding: 5px;
}

.language__label {
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 30px;
  padding: 7px 18px 5px 0;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 7px 7px;
  color: var(--black);
  z-index: 9999999;
  background-size: 14px;
  text-align: right;

}
.language__container--left .language__label {
  border-radius: 4px 0 0 4px;
}
.language__container--right .language__label {
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.language__control:hover + .language__label,
.language__control:focus + .language__label {
  background-color: #eee;
}
.language__control:checked + .language__label {
  background-color: #ddd;
}
/* .language__control:focus + .language__label,
.language__control:checked:focus + .language__label {
  z-index: 2;
  outline: 2px solid #85bffd;
  box-shadow: 0 0 8px #85bffd;
} */


.language_ul{
  padding-left: 15vh;
}

@media screen and (max-width: 768px) {
  .nav_white {
    display: none;
  }
}
