@font-face {
  font-family: "Eurostile Extended";
  src: url("../../fonts/eurostile-bold-extended.otf");
}
@font-face {
  font-family: "Eurostile Extended";
  src: url("../../fonts/eurostile-extd-medium.otf");
}
@font-face {
  font-family: "Eurostile Extended";
  src: url("../../fonts/eurostile-extended-2.ttf");
}
@font-face {
  font-family: "Eurostile Extended";
  src: url("../../fonts/eurostile-extended-black.ttf");
}

*,
*::before,
*::after {
  font-family: "Eurostile Extended", sans-serif;
}
