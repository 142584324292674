.contact {
  padding-top: 5vh;
  padding-bottom: 10vh;
}

.contact_footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-left: 5px;
  margin-top: 5px;
}

.adress_contact {
  font-size: 28px;
  margin-left: 1vh;
}

.low_opacity_contact {
  opacity: 0.5;
  margin-bottom: 0vh;
  font-size: 14px !important;
  margin-left: 3.8vh;
}

.low_opacity_contact_mail {
  opacity: 0.5;
  margin-bottom: 0vh;
  font-size: 14px !important;
}
.low_opacity_contact_mail a {
  color: var(--black) !important;
}

.mail_end {
  position: relative;
  top: 7.8vh;
  cursor: pointer;
}

.mail_end_res {
  display: flex;
  justify-content: end;
}

.contact_pin_icon {
  color: var(--red);
  font-size: 1.5em;
  font-weight: 900;
}

.padding_button {
  padding-right: 1.5vh;
  padding-left: 1.6vh;
}

.contact_res_center {
  display: flex;
  justify-content: end;
}

.contact_left_text {
  font-size: 6.5em;
}

.stroke_title_contact {
  color: black;
  font-size: 6.5em;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: white;
}

.form-control {
  margin-top: 1vh;
  border-bottom: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-top: transparent !important;
  border-right: transparent !important;
  border-radius: 0 !important;
  font-size: 12px;
}

.form-control:focus {
  color: #495057;
  border-bottom: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-top: transparent !important;
  border-right: transparent !important;
  background-color: transparent !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent !important;
}

.contact_button {
  background-color: white;
  color: var(--black);
  border-radius: 9px;
  border: 2px solid var(--black);
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent !important;
}

.padding_button_contact {
  padding: 0.5vh 16vh 0.5vh 16vh;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .padding_button_contact {
    padding: 0.5vh 11vh 0.5vh 11vh;
  }
}

@media screen and (max-width: 992px) {

  .contact_footer {
    display: flex;
    justify-content: start;
    gap: 10px;
    margin-left: 5px;
    margin-top: 5px;
  }

  .contact_res_center_two {
    display: flex;
    justify-content: center;
  }

  .contact_res_center {
    display: flex;
    justify-content: center;
  }

  .mobile_adress {
    margin-top: 5vh;
  }

  .adress_end {
    display: flex;
    justify-content: center;
  }

 

  .mail_end_res {
    display: flex;
    justify-content: start;
  }

  .res_mail_start {
    margin-top: -1.6vh;
  }

  /*   .left_form {
    padding-left: 7em;
  } */
}

@media screen and (max-width: 568px) {
  .left_form {
    padding-right: 0;
  }

  .contact_left_text {
    font-size: 3em;
  }

  .contact_res_center {
    display: flex;
    justify-content: center;
  }

  .stroke_title_contact {
    font-size: 3em;
    display: flex;
    justify-content: center;
    -webkit-text-stroke: 1px black;
  }
  .contact-arrow-down {
    width: 2%;
    margin-right: 2vh;
    margin-left: 1vh;
  }
  .contact_res_center_two {
    display: flex;
    justify-content: center;
  }

  .res_mail_start {
    margin-top: -1.8vh;
    margin-left: 11vh;
  }
}
