.oneBoxImage {
  position: relative;
  display: flex;
  justify-content: center;
}
.oneBoxImage img {
  position: absolute;
  bottom: 0;
}
.oneBoxImage span {
  position: absolute;
  top: 1rem;
}

.boxcss span {
  font-size: 15px;
}

.twoBoxImage {
  position: relative;
  display: flex;
  justify-content: center;
}
.twoBoxImage img {
  position: absolute;
  top: 0;
}
.twoBoxImage span {
  position: absolute;
  bottom: 1rem;
}
.threeBoxImage {
  position: relative;
  display: flex;
  align-items: center;
}
.threeBoxImage img {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90% !important;
}
.threeBoxImage span {
  position: absolute;
  right: 1.5rem;
}
.fourBoxImage {
  position: relative;
  display: flex;
  justify-content: center;
}
.fourBoxImage img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.fourBoxImage span {
  position: absolute;
  top: 1rem;
}
.fiveBoxImage {
  position: relative;
  display: flex;
  justify-content: center;
}
.fiveBoxImage img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.fiveBoxImage span {
  position: absolute;
  top: 1rem;
}
.img-fluid {
  max-width: 90% ;
}
.sixBoxImage {
  position: relative;
  display: flex;
  justify-content: center;
}
.sixBoxImage img {
  position: absolute;
  top: 0;
  left: 1px;
}
.sixBoxImage span {
  position: absolute;
  bottom: 1rem;
}
.threeBoxImagePlus {
  position: relative;
  display: flex;
  align-items: center;
}
.threeBoxImagePlus img {
  position: absolute;
 top: 0;
  left: 11px;
  height: 90% !important;
}
.threeBoxImagePlus span {
  position: absolute;
  right: 0.5rem;
}

.oneBoxImagePlus {
  position: relative;
  display: flex;
  justify-content: center;
}
.oneBoxImagePlus img {
  position: absolute;
  top: 0;
  right: 0;
}
.oneBoxImagePlus span {
  position: absolute;
  top: 7rem;
  left: 12px;
}
.threeBoxImageVisox {
  position: relative;
  display: flex;
  align-items: center;
}
.threeBoxImageVisox img {
  position: absolute;
  top: 0;
  left: 0;

}
.threeBoxImageVisox span {
  position: absolute;
  right: 1rem;
  bottom: 0;
}
.boxcss {
  border: 1px solid #dddddd;
  border-radius: 20px;
  background: #dddddd;
}
.boxcss img {
  height: 70%;
}
.boxcss span h6{
  font-size: 12px;

  color: #4e4d4d;
}

.boxcssPlus {
  border: 1px solid #dddddd;
  border-radius: 20px;
  background: #dddddd;
}
.boxcssPlus img {
  height: 100%;
}

.product_video {
  border-radius: 20px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999999999999999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.popup video {
  max-width: 80%;
  max-height: 80%;
}

.video_button {
  cursor: pointer;
  width: 5vh;
  color: var(--white);
  position: relative;
  top: 40%;
  right: 50%;
}

.product_video {
  cursor: pointer;
}

.scrol_img_plus {
  width: 120%;
}

@media only screen and (max-width: 568px) {
  .product_video {
    max-width: 100%;
    max-height: 100%;
  }
  .scrol_img_plus {
    width: 265%;
  }
}

@media only screen and (max-width: 1300px) {
  .img-fluid {
    max-width: 65% ;
  }
  .boxcss span {
    font-size: 9px;
  }
  .boxcssPlus span {
    font-size: 9px;
  }
}
@media only screen and (min-width: 1000px) and (max-device-width: 1300px) {
  .img-fluid {
    max-width: 65% ;
  }
  .fiveBoxImage img{
    max-width: 90% !important;
  }
  .sixBoxImage img{
    max-width: 90% !important;
  }
  .threeBoxImage span{
    top: 0.5vh;
  }

}
@media only screen and (max-width: 768px) {
  .img-fluid{
    max-width: 61%;
  }
  .boxcss span{
    font-size: 7px;
  }
  .boxcss span h6{
    font-size: 7px;
  }
  .threeBoxImage img{
    height: 82%;
  }
  .threeBoxImage span {
    position: absolute;
    right: 4px;
  }
  .fiveBoxImage img{
    max-width: 90% !important;
  }
  .sixBoxImage img{
    max-width: 90% !important;
  }
}

